<template>
  <div>
    <slot name="activator" :on="showDialog"></slot>
    <!-- <div href="javascript:;" @click="showDialog()"> -->
    <slot></slot>
    <!-- </div> -->
    <app-dialog v-model="dialog" :title="berita_acara_header.document_no_" :loading="loading" class="dialogdetail" max-width="1400">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>

        <app-log-data-dialog module_name="beritaacara" :id="berita_acara_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>


        <v-divider vertical class="mx-2"></v-divider>

        <app-button @click="redirect('Accounting.BeritaAcara.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button :disabled="!enableEditBtn" @click="redirect('Accounting.BeritaAcara.Edit', { id: id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
        <app-button :disabled="!enableDeleteBtn" @click="deleteData()" :loading="loading_delete" title="Delete Document" mdi-icon="mdi-delete"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
        <app-button :disabled="!enableApproveBtn" @click="approveData()" :loading="loading_approve" title="Approve Document" mdi-icon="mdi-file-check"></app-button>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button :disabled="!enableCancelBtn" @click="cancelData()" :loading="loading_cancel" title="Cancel Document" mdi-icon="mdi-file-document-remove"></app-button>
        <app-button :disabled="!enableReopenBtn" @click="reopenData()" :loading="loading_reopen" title="Re-Open Document" mdi-icon="mdi-file-restore"></app-button>
        <app-button :disabled="!enableCloseBtn" @click="closeData()" :loading="loading_close_document" title="Closed Document" mdi-icon="mdi-book-check"></app-button>
      </template>

      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col cols="12">
              <card-expansion title="General">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field label="Document No." v-model="berita_acara_header.document_no_" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Date" v-model="berita_acara_header.document_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Area Code" v-model="berita_acara_header.area_code" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Document Type" v-model="berita_acara_header.document_type" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-textarea label="Description" v-model="berita_acara_header.description" hide-details outlined dense class="mb-2" readonly rows="4"></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Created By" v-model="berita_acara_header.created_by_username" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Created Date" v-model="berita_acara_header.created_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Status" v-model="berita_acara_header.status" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Target Date" v-model="berita_acara_header.target_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Last Updated" v-model="berita_acara_header.last_updated" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Closing Date" v-model="berita_acara_header.closing_date" hide-details outlined dense class="mb-2" readonly></v-text-field>
                    <v-text-field label="Total Price" prefix="Rp. " v-model="totalPriceFormatted" hide-details outlined dense class="mb-2 font-weight-bold" readonly></v-text-field>
                  </v-col>
                </v-row>
                <div>
                  <app-button @click="redirect('Accounting.BeritaAcara.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find" label="View Detail"></app-button>
                </div>
              </card-expansion>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <card-expansion :hide-title="true">
                <template #title>
                  <v-tabs v-model="tab">
                    <v-tab>Items</v-tab>
                    <v-tab>Attachment</v-tab>
                    <v-tab>Approval</v-tab>
                  </v-tabs>
                </template>

                <!-- Item -->
                <v-tabs-items v-model="tab" class="elevation-1">
                  <v-tab-item>
                    <div class="px-4">
                      <v-checkbox @change="selectedApprovalItemToogle = !selectedApprovalItemToogle" label="Select/Unselect All" v-show="isShowSelectedApprovalItem"></v-checkbox>
                    </div>
                    <!-- <v-row> -->
                    <!-- <v-col cols="12"> -->
                    <v-sheet elevation="1">
                      <!-- <v-toolbar elevation="0" dense>
                      <v-toolbar-title>Items</v-toolbar-title>
                    </v-toolbar> -->
                      <v-divider></v-divider>
                      <v-data-table :items="berita_acara_item" :headers="berita_acara_item_columns">
                        <template v-slot:[`item.item_approved`]="{ item }">
                          <div v-if="isShowSelectedApprovalItem">
                            <div v-if="item.original_item_approved === null">
                              <v-simple-checkbox v-model="item.item_approved" :ripple="false" color="primary"></v-simple-checkbox>
                            </div>
                            <div v-if="item.original_item_approved === false">
                              <v-simple-checkbox v-model="item.item_approved" :ripple="false" disabled="disabled" color="warning" title="Di tolak pada Approval sebelumnya." indeterminate></v-simple-checkbox>
                            </div>
                            <div v-if="item.original_item_approved === true">
                              <v-simple-checkbox v-model="item.item_approved" :ripple="false" color="primary"></v-simple-checkbox>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="item.item_approved === null">
                              <v-icon>mdi-minus</v-icon>
                            </div>
                            <div v-if="item.item_approved !== null">
                              <v-icon v-if="item.item_approved" color="primary">mdi-check</v-icon>
                              <v-icon v-if="!item.item_approved" color="warning">mdi-cancel</v-icon>
                            </div>
                          </div>
                        </template>
                        <template v-slot:[`item.item_image`]="{ item }">
                          <image-viewer :src="resourceUrl(item.item_image)" :title="item.item_description + ' - ' + item.description
                            ">
                            <v-img :src="resourceUrl(item.item_image)" max-width="60" max-height="60" class="mx-auto"></v-img>
                          </image-viewer>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                          <span>{{ numberFormat(item.quantity, 2) }}</span>
                        </template>
                        <template v-slot:[`item.weight`]="{ item }">
                          <span>{{ numberFormat(item.weight, 2) }}</span>
                        </template>
                        <template v-slot:[`item.unit_price`]="{ item }">
                          <span>{{ numberFormat(item.unit_price, 2) }}</span>
                        </template>
                        <template v-slot:[`item.subtotal`]="{ item }">
                          <span>{{ numberFormat(item.quantity * item.unit_price, 2) }}</span>
                        </template>
                      </v-data-table>
                    </v-sheet>
                    <!-- </v-col> -->
                    <!-- </v-row> -->
                  </v-tab-item>
                  <v-tab-item>
                    <!-- Attachment -->
                    <v-row>
                      <v-col cols="12">
                        <v-sheet elevation="1">
                          <v-toolbar elevation="0" dense>
                            <!-- <v-toolbar-title>Attachments</v-toolbar-title> -->
                            <v-spacer></v-spacer>
                            <AddAttachment :callback="saveAttachment">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn class="ma-1" text small fab v-bind="attrs" v-on="on">
                                    <v-icon>mdi-attachment</v-icon>
                                  </v-btn>
                                </template>
                                <span>Add New Attachment</span>
                              </v-tooltip>
                            </AddAttachment>
                          </v-toolbar>
                          <v-divider></v-divider>
                          <v-data-table :items="berita_acara_attachment" :headers="berita_acara_attachment_columns" hide-default-header>
                            <template v-slot:[`item.title`]="{ item }">
                              <span>
                                <a :href="resourceUrl(item.file_src)" target="_blank">{{ item.title }}</a> </span><br />
                              <span>{{ item.description }}</span>
                            </template>
                            <template v-slot:[`item.id`]="{ item }">
                              <EditAttachment :selectedItemData="item" :callback="updateAttachment">
                                <v-btn small icon>
                                  <v-icon color="primary" small>mdi-pencil</v-icon>
                                </v-btn>
                              </EditAttachment>
                              <v-btn small @click="deleteAttachment(item)" icon>
                                <v-icon color="primary" small>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <!-- Approval -->
                    <v-row>
                      <v-col cols="12">
                        <v-sheet elevation="1">
                          <v-toolbar elevation="0" dense>
                            <!-- <v-toolbar-title>Approval</v-toolbar-title> -->
                          </v-toolbar>
                          <v-divider></v-divider>
                          <v-data-table :items="berita_acara_approval" :headers="berita_acara_approval_columns">
                            <template v-slot:[`item.approved`]="{ item }">
                              <span v-if="item.approved == '1'">
                                <v-icon color="primary">mdi-check</v-icon>
                              </span>
                            </template>
                          </v-data-table>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <CommentList :berita_acara_header_id="id"></CommentList>
        </v-col>
      </v-row>

    </app-dialog>
  </div>
</template>

<script>
import ImageViewer from "@/components/helper/ImageViewer.vue";
import AddAttachment from "./AddAttachment";
import EditAttachment from "./EditAttachment";
import CommentList from "./CommentList";
// import config from "@/constants/config";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ImageViewer,
    AddAttachment,
    EditAttachment,
    CommentList,
  },
  data() {
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close_document: false,
      loading_approve: false,
      loading_cancel: false,
      loading_attachment: false,
      dialog: false,
      tab: null,
      modal1: false,
      berita_acara_header: {},
      user: {},
      berita_acara_item_columns: [
        {
          text: "Approved",
          value: "item_approved",
          sortable: false,
          align: "center",
        },
        {
          text: "Image",
          value: "item_image",
          sortable: false,
          align: "center",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          cellClass: "text-no-wrap",
          class: "text-no-wrap",
        },
        { text: "Item No.", value: "item_no_", sortable: false },
        {
          text: "Item Description",
          value: "item_description",
          sortable: false,
          cellClass: "text-no-wrap",
          class: "text-no-wrap",
        },
        {
          text: "UoM",
          value: "unit_of_measure",
          sortable: false,
          align: "center",
        },
        {
          text: "Quantity",
          value: "quantity",
          sortable: false,
          align: "right",
        },
        { text: "Weight", value: "weight", sortable: false, align: "right" },
        {
          text: "Unit Price",
          value: "unit_price",
          sortable: false,
          align: "right",
        },
        {
          text: "Sub Total",
          value: "subtotal",
          sortable: false,
          align: "right",
        },
      ],
      berita_acara_item: [],
      berita_acara_attachment_columns: [
        { text: "Attachment", value: "title", sortable: false },
        { text: "", value: "id", sortable: false, align: "right" },
      ],
      berita_acara_attachment: [],
      berita_acara_approval_columns: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        {
          text: "Approve Status",
          value: "approved",
          sortable: false,
          align: "center",
        },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      berita_acara_approval: [],
      berita_acara_comment: [],
      fullscreen: false,

      selectedApprovalItemToogle: false,
    };
  },
  watch: {
    dialog(n, o) {
      if (o == true && n == false) {
        this.$emit("callback");
      }
    },
    // eslint-disable-next-line no-unused-vars
    selectedApprovalItemToogle(n, o) {
      this.berita_acara_item.forEach((item) => {
        if ((item.original_item_approved === null) || (item.original_item_approved === true)) {
          item.item_approved = n;
        }
      })
    },
  },
  computed: {
    totalPrice() {
      var total = 0;
      this.berita_acara_item.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return total;
    },
    totalPriceFormatted() {
      var total = 0;
      this.berita_acara_item.forEach((item) => {
        total = total + item.quantity * item.unit_price;
      });
      return this.numberFormat(total);
    },
    enableDeleteBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.berita_acara_header.created_by_username !==
        this.$store.state.loginInfo.username
      )
        return false;

      if (
        this.modulePermission("beritaacara", "delete") &&
        !this.inArray(this.berita_acara_header.status, ["approved"]) &&
        this.inArray(this.berita_acara_header.status, ["open"])
      ) {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.berita_acara_header.created_by_username !==
        this.$store.state.loginInfo.username
      )
        return false;

      if (
        this.modulePermission("beritaacara", "reopen") &&
        !this.inArray(this.berita_acara_header.status, ["approved"]) &&
        !this.inArray(this.berita_acara_header.status, ["open"])
      ) {
        return true;
      }
      return false;
    },
    enableCancelBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.modulePermission("beritaacara", "cancel") &&
        !this.inArray(this.berita_acara_header.status, ["approved"]) &&
        !this.inArray(this.berita_acara_header.status, ["cancel"])
      ) {
        if (this.berita_acara_header.created_by_username === this.$store.state.loginInfo.username) {
          return true;
        }
      }
      return false;
    },
    enableSendApprovalBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.modulePermission("beritaacara", "send-approval") &&
        this.berita_acara_header.status == "open"
      ) {
        return true;
      }
      return false;
    },
    enableApproveBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.modulePermission("beritaacara", "approve") &&
        this.berita_acara_header.status == "waiting-approval"
      ) {
        let show = false;
        let l_uname = this.$store.state.loginInfo.username;
        for (var i = 0; i < this.berita_acara_approval.length; i++) {
          let apprv = this.berita_acara_approval[i].approved;
          let uname = this.berita_acara_approval[i].username;

          if (apprv == 0) {
            if (uname == l_uname) {
              show = true;
              return true;
            }
          }
        }

        return show;
      }
      return false;
    },
    enableEditBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.modulePermission("beritaacara", "modify") &&
        this.berita_acara_header.status == "open"
      ) {
        return true;
      }
      return false;
    },
    enableEditAccountingBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        (this.modulePermission("beritaacara", "update-book-value")
          || this.modulePermission('beritaacara', 'update-acquisition-value')
        ) &&
        this.inArray(this.berita_acara_header.status, ["open", "waiting-approval", "approved"])
      ) {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (this.inArray(this.berita_acara_header.status, ["closed", "cancel"]))
        return false;

      if (
        this.modulePermission("beritaacara", "close") &&
        this.berita_acara_header.status == "approved"
      ) {
        return true;
      }
      return false;
    },
    isFullscreen() {
      if (this.isMobile) return true;
      if (this.fullscreen) return true;

      return false;
    },
    isShowSelectedApprovalItem() {
      if (this.modulePermission("beritaacara", "approve")) {

        if (this.inArray(this.berita_acara_header.status, ["waiting-approval"]))
          return true;

      }

      return false;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    showDialog() {
      this.dialog = true;
      this.getDetail(true);
    },

    async getDetail(refresh = false) {
      this.loading = true;

      this.berita_acara_header = {};
      this.berita_acara_item = [];
      this.berita_acara_attachment = [];
      this.berita_acara_approval = [];
      this.berita_acara_comment = [];
      this.user = {};

      if (refresh == true) this.AxiosStorageRemove("GET", "beritaacara/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("beritaacara/detail", config)
        .then((res) => {
          this.berita_acara_header = res.data.data.berita_acara_header;
          res.data.data.berita_acara_item.forEach((item) => {
            item.original_item_approved = item.item_approved;

            this.berita_acara_item.push(item);
          })
          this.berita_acara_attachment = res.data.data.berita_acara_attachment;
          this.berita_acara_approval = res.data.data.berita_acara_approval;
          this.berita_acara_comment = res.data.data.berita_acara_comment;
          this.user = res.data.data.user;

          this.showLoadingOverlay(false);

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);
        await this.$axios
          .post("beritaacara/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Re-open document?", post);
    },

    sendApproval() {
      const post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);
        await this.$axios
          .post("beritaacara/send-approval", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Send Approval Document?", post);
    },

    async approveData() {
      const post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);

        let i = 0;
        this.berita_acara_item.forEach((item) => {

          formData.append("item_approved[" + i + "][id]", item.id);
          formData.append("item_approved[" + i + "][berita_acara_header_id]", item.berita_acara_header_id);
          formData.append("item_approved[" + i + "][item_no_]", item.item_no_);
          formData.append("item_approved[" + i + "][item_approved]", item.item_approved);

          i++;
        })


        await this.$axios
          .post("beritaacara/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve Document?", post);
    },

    async cancelData() {
      const post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);
        await this.$axios
          .post("beritaacara/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    async deleteData() {
      const post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);
        await this.$axios
          .post("beritaacara/delete", formData)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.dialog = false;
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Delete document?", post);
    },

    async saveAttachment(data) {
      this.loading_attachment = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("berita_acara_header_id", this.berita_acara_header.id);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("file_src", data.file_src);
      await this.$axios
        .post("beritaacara/attachment/save", formData)
        .then((res) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async updateAttachment(data) {
      this.loading_attachment = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", data.id);
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("file_src", data.file_src);
      await this.$axios
        .post("beritaacara/attachment/update", formData)
        .then((res) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);

          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_attachment = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async deleteAttachment(data) {
      const post = async () => {
        this.loading_attachment = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", data.id);
        await this.$axios
          .post("beritaacara/attachment/delete", formData)
          .then((res) => {
            this.loading_attachment = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_attachment = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Delete attachment?", post);
    },

    async closeData() {
      const post = async () => {
        this.loading_close_document = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.berita_acara_header.id);
        await this.$axios
          .post("beritaacara/close", formData)
          .then((res) => {
            this.loading_close_document = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_close_document = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Close document?", post);
    },

    // async printDocument() {
    //   this.loading = true;
    //   this.showLoadingOverlay(true);

    //   var config2 = {
    //     params: {
    //       id: this.id,
    //     },
    //     cacheConfig: false,
    //   };
    //   await this.$axios
    //     .get("beritaacara/print/session", config2)
    //     .then((res) => {
    //       setTimeout(() => {
    //         this.loading = false;
    //         this.showLoadingOverlay(false);
    //         const url =
    //           config.apiUrl +
    //           "beritaacara/print?id=" +
    //           this.id +
    //           "&key=" +
    //           res.data.data.key;

    //         // console.log(url);
    //         this.goToExternalURL(url);
    //       }, 2000);
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       this.showLoadingOverlay(false);
    //       this.axiosErrorHandler(error);
    //     });
    // },

    printDocument() {
      this.redirect('Accounting.BeritaAcara.PrintDocument', { id: this.id });
    },
  },
};
</script>


<style scoped>
.vcard-text-dd-hh {
  max-height: 550px;
  overflow-y: auto;

}

div.cpre {
  max-height: 260px;
  overflow-y: auto;
}

.ipre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
</style>