<template>
  <div>
    <v-card elevation="1" outlined>
      <v-toolbar dense elevation="0">
        <v-toolbar-title title="Displays the List that you must Approve.">My Approval</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <div style="min-height: 460px; max-height: 600px; overflow-y: auto">
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate small></v-progress-circular>
        </div>
        <div v-else>
          <v-alert class="mx-2" dense type="warning" v-if="results.length == 0">
            Not Found!
          </v-alert>
          <v-card elevation="0" class="ma-1" outlined v-for="item in results" :key="item.id">
            <v-card-title class="mb-1 pb-1 py-1 px-3 text-sm">
              <detail-dialog :id="parseInt(item.id)" @callback="getData(true)">
                <template v-slot:activator="{ on }">
                  <a href="javascript:;" v-on:click="on">{{ item.document_no_ }}</a>
                </template>
              </detail-dialog>
            </v-card-title>
            <v-card-subtitle class="py-1 px-3">{{ item.document_type }}</v-card-subtitle>
            <v-card-text class="py-1 px-3">
              <v-row>
                <v-col class="text-start"> Area : {{ item.area_code }} </v-col>
                <v-col class="text-end">
                  <span>{{ appDateFormat(item.document_date) }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import DetailDialog from "./DetailDialog.vue";
export default {
  components: { DetailDialog },
  data() {
    return {
      loading: null,
      search: "",
      results: [],
    };
  },

  methods: {
    refreshData() {
      this.getData();
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("GET", "beritaacara/my-approval-list");

      this.loading = true;
      this.$axios
        .get("beritaacara/my-approval-list")
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.results = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
