<template>
  <div>
    <v-container>
      <page-title title="Berita Acara">
        <template slot="action">
          <approval-template v-if="modulePermission('beritaacara', 'create')" class="ms-1"></approval-template>
          <export-data v-if="modulePermission('beritaacara', 'export')" class="ms-1"></export-data>
        </template>
      </page-title>

      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>My List</v-tab>
              <v-tab v-if="modulePermission('beritaacara', 'approve')">
                <v-badge dot v-if="badgeMyApproval">My Approval</v-badge>
                <span v-else>My Approval</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
              <v-tab-item v-if="modulePermission('beritaacara', 'approve')">
                <MyApproval ref="refMyApproval"></MyApproval>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogExport" :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title>
          <span>test</span>
        </v-card-title>
        <v-card-text>
          <v-text-field></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary">Export</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogExport = !dialogExport">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
import MyApproval from "./components/MyApprovalList.vue";
import ApprovalTemplate from "./components/ApprovalTemplate.vue";
import ExportData from "./components/ExportData.vue";
export default {
  components: {
    ListData,
    ApprovalTemplate,
    MyApproval,
    ExportData,
  },
  data() {
    return {
      tab: null,
      dialogExport: null,


      badgeMyApproval: false,
    };
  },

  computed: {},

  methods: {
    async showBadgeMyApproval(refresh) {
      const uri = "beritaacara/notif-exists";
      this.badgeMyApproval = false;
      this.badgeMyTask = false;

      if (refresh) this.AxiosStorageRemove("GET", uri);


      await this.$axios
        .get(uri, {
          sessionStorage: !refresh,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.badgeMyApproval = resData.data.approval;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.axiosErrorHandler(error);

          this.badgeMyApproval = false;
          this.badgeMyTask = false;
        });
    },

  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("beritaacara", "view", true);

    this.showBadgeMyApproval();
  },
};
</script>
